const getCallStatusCode = (status) => {
  const statusCodes = {
    n: "not_available",
    a: "available",
    b: "busy",
  };

  return statusCodes[status] || "";
};

module.exports = {
  getCallStatusCode,
};
