<template>
  <v-container class="pa-0 main_lobby" fluid ref="main-lobby-container">      
    <main-image />
  </v-container>
</template>
<script>
import MainImage from "@/components/MainImage";
export default {
  components: {MainImage},
  data () {
    return {
      
    }
  },
  computed: {   
  },
  watch :{
   
  },
  methods : {
   
  },
  mounted(){
    this.$vuetify.goTo(this.$refs['main-lobby-container'],{
      offset : -200      
    })
  }
}
</script>
<style>
.test_class{
  position: fixed;
  top: 50%;
  right: 3%;
}
</style>
