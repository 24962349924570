const state = {
  show_agenda_modal : false,
  agenda_list : {}
}
const actions = {
  fetchAgenda({commit}){
    axios.get("/agenda/list")
    .then(function (response) {
      let res_data = {
        key : 'agenda_list',
        data : response.data.data
      }
      commit('setAgendaData',res_data)
    })
    .catch(function (e) {
      console.log(e);
    });
  }
}
const getters = {}
const mutations = {
  setAgendaData(state,data){
    state[data.key] = data.data
  },
}
import axios from "@/helper/axios";
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}