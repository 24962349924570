import axios from "axios";
let userdata = JSON.parse(localStorage.getItem("pcm_user"));
let token;
if (
  userdata &&
  userdata.attendee_token != "" &&
  userdata.attendee_token != undefined
) {
  token = userdata.attendee_token;
  axios.defaults.baseURL = process.env.VUE_APP_API_URL + "/user/";
} else {
  axios.defaults.baseURL = process.env.VUE_APP_API_URL;
}

axios.defaults.headers.common = {
  Authorization: "Bearer " + token,
  Accept: "application/json",
};
// axios.interceptors.response.use(response => {
//     return response;
// }, error => {
//     if (error.response.status === 401) {
//         localStorage.removeItem('pcm_user')
//         window.location = '/'
//     }
//     return error;
// });
export default axios;
