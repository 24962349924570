<template>
<div>
  <v-dialog
    v-model="show_totebag_modal"
    :max-width="maxPopupWidth"
    @click:outside="closePopup"
    persistent
  >
    <v-card>
      <v-toolbar dark flat color="primary" dense>
        <v-toolbar-title>Tote Bag</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="closePopup">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-divider></v-divider>
      <v-row no-gutters>
        <v-col cols="9">
          <v-card flat>
            <v-card-text class="px-1 py-0">
              <v-list>
                <v-btn color="red darken-3 ma-2 mb-4" v-if="selected.length" dark @click="removeDocument">Remove</v-btn>
                <v-divider v-if="selected.length" />
                <div v-for="(item, index) in totebag_list" :key="index">
                  <v-list-item>
                    <v-checkbox v-model="selected" :value="item.tote_bag_id"></v-checkbox>
                    <!--<v-btn color="red" icon class="mx-1">
                      <v-icon>mdi-file-pdf-box-outline</v-icon>
                    </v-btn>-->
                    <v-btn
                      v-if="item.content_type == 1 && item.session && item.session.pre_recorded_video && isCompleted(item.session)"
                      :color="item.session.pre_recorded_video ? 'orange' : 'primary'"
                      dark
                      class="mx-1"
                      @click="openVideo(item.session.pre_recorded_video)"
                    >
                      {{  (item.session.pre_recorded_video) ? 'View' : 'Available soon' }}
                    </v-btn>
                    <v-btn
                      v-if="item.content_type == 1 && item.session && !isCompleted(item.session)"
                      color="primary" 
                      class="mx-1"
                      :to="'/app/session/' + item.session.session_id"
                      @click="closePopup()"
                    >
                      Join
                    </v-btn>
                    <v-btn color="orange" class="mx-1" :href="item.resource.file" target="_blank" v-if="item.content_type==2" > View </v-btn>
                    <v-list-item-content class="mx-4" v-if="item.content_type==1">
                      <v-list-item-title>{{item.session.title}}</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-content class="mx-4" v-if="item.content_type==2">
                      <v-list-item-title>{{item.resource.title}}</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-btn icon v-if="item.content_type == 2 && item.resource.file_type != 'LINK'" @click="addResourceView(item.resource.resource_id,'1')" :href="getFileUrl(item.resource.file_key)" target="_blank">
                        <v-icon>mdi-download-circle</v-icon>
                      </v-btn>
                      <v-tooltip top v-if="item.content_type == 1 && item.session">
                        <template v-slot:activator="{ on }">
                          <span v-on="on" class="action" :aria-disabled="!isCompleted(item.session)">
                            <v-btn
                              icon
                              @click="downloadSessionVideo(item.session.session_id)"
                              :disabled="!isCompleted(item.session)"
                              :loading="loadingUrlFor[item.session.session_id]"
                            >
                              <v-icon>mdi-download-circle</v-icon>
                            </v-btn>
                          </span>
                        </template>
                        <span>{{ !isCompleted(item.session) ? "After the session, it will be available for download." : "Download Video" }}</span>
                      </v-tooltip>
                    </v-list-item-action>
                  </v-list-item>
                  <v-divider
                    v-if="index != totebag_list.length - 1"
                  ></v-divider>
                </div>
                <div v-if="totebag_list.length == 0" class="text-center py-3">
                  No Document Found!
                </div>
              </v-list>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="3">
          <div class="d-flex align-center" :style="{height : '100%'}">
             <div class="d-flex flex-column self-center" style="width:100%">
                <v-card class="pa-2" outlined tile @click="$helper.openUrl(totebag_advertise.ADVERTISE_1_LINK)" :ripple="totebag_advertise.ADVERTISE_1_LINK!=null">
                  <v-img :src="totebag_advertise.ADVERTISE_1"/>
                </v-card>
                <v-card class="pa-2" outlined tile @click="$helper.openUrl(totebag_advertise.ADVERTISE_2_LINK)" :ripple="totebag_advertise.ADVERTISE_2_LINK!=null">
                  <v-img :src="totebag_advertise.ADVERTISE_2"/>
                </v-card>
             </div>
          </div>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
  <v-dialog v-model="show_preview" max-width="50%">
      <v-card>
        <v-card-text class="pt-2 rounded-lg px-0 py-0">
          <video :src="preview_data" width="100%" autoplay v-if="preview_data" controls controlsList="nodownload"></video>
        </v-card-text>
        <v-btn style="position:absolute;top:0px;right:10px" color="red" dark fab small @click="show_preview = false">          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card>
  </v-dialog>
</div>
</template>
<script>
import axios from "@/helper/axios";
import { mapState, mapActions, mapMutations } from "vuex";
export default {
  name: "TotebagPopup",
  data() {
    return {
      selected : [],
      preview_data: null,
      show_preview: false,
      loadingUrlFor: {},
      baseURL : axios.defaults.baseURL
    };
  },
  watch: {
    show_totebag_modal(value) {
      if (value) {
        this.fetchTotebag();
        this.fetchAgenda();
        this.fetchAdertisement()
      }
    },
    show_preview : function(value){
      if(!value){
       this.preview_data = null
      }
    }
  },
  computed: {
    ...mapState("totebag", ["show_totebag_modal", "totebag_list","agenda_list","totebag_advertise"]),
    ...mapState("utils", ["maxPopupWidth"]),
  },
  methods: {
  ...mapActions("totebag", ["fetchTotebag","fetchAdertisement","fetchAgenda"]),
    ...mapMutations("totebag", ["setTotebagData"]),
    ...mapMutations("utils", ["setAlert"]),
    addResourceView(resource_id, is_download) {
      let _self = this;
      axios
        .post("/resource/add_view",{resource_id: resource_id, is_download: is_download})
        .then(function () {

        })
        .catch(function (e) {
          console.log(e);
        });
      _self.loading = false;
    },
    closePopup() {
      this.setTotebagData({
        key: "show_totebag_modal",
        data: false,
      });
    },
    getTimeSlot(session) {
      return (
        this.moment(session.start_time).format("ddd, MMM Do h:mm a") +
        " - " +
        this.moment(session.end_time).format("h:mm a")
      );
    },
    addEvent(session_id) {
      let _self = this;
      this.loading = "secondary";
      axios.post(`session/add_event`, {session_id:session_id})
        .then((response) => {
          let response_data = response.data;
          if (response_data.status) {
            _self.setAlert({
              show: true,
              variant: "success",
              message: response_data.message,
              dismissCountDown: 5000,
            });
          }
          else {
            let errors = response_data.error;
            for (let key in errors) {
              _self.setAlert({
                show: true,
                variant: "danger",
                message: errors[key].message,
                dismissCountDown: 5000,
              });
            }
          }
          _self.loading = false;
        })
        .catch((e) => {
          console.log(e);
          _self.loading = false;
        });
    },
    removeDocument(){
      let _self = this;
      this.loading = "secondary";
      axios.post(`tote_bag/delete`, {selected_id:this.selected})
      .then((response) => {
        let response_data = response.data;
        if (response_data.status) {
          _self.selected = []
          _self.fetchTotebag();
          _self.setAlert({
            show: true,
            variant: "success",
            message: response_data.message,
            dismissCountDown: 5000,
          });
        }
        else {
          let errors = response_data.error;
          for (let key in errors) {
            _self.setAlert({
              show: true,
              variant: "danger",
              message: errors[key].message,
              dismissCountDown: 5000,
            });
          }
        }
        _self.loading = false;
      })
      .catch((e) => {
        console.log(e);
        _self.loading = false;
      });
    },
    removeAgenda(agenda_id){
      let _self = this;
      this.loading = "secondary";
      axios.post(`agenda/delete`, {agenda_id:agenda_id})
      .then((response) => {
        let response_data = response.data;
        if (response_data.status) {
          _self.selected = []
          _self.fetchAgenda();
          _self.setAlert({
            show: true,
            variant: "success",
            message: response_data.message,
            dismissCountDown: 5000,
          });
        }
        else {
          let errors = response_data.error;
          for (let key in errors) {
            _self.setAlert({
              show: true,
              variant: "danger",
              message: errors[key].message,
              dismissCountDown: 5000,
            });
          }
        }
        _self.loading = false;
      })
      .catch((e) => {
        console.log(e);
        _self.loading = false;
      });
    },
    openVideo(url){
      this.show_preview = true;
      this.preview_data = url;
    },
    downloadSessionVideo(sessionId) {
      if (!sessionId) {
        console.log("ERROR: session id not found")
        return
      }

      this.loading = true;
      this.loadingUrlFor = {
        ...this.loadingUrlFor,
        [sessionId]: true
      }

      axios.get(`session/${sessionId}/get_download_link`)
      .then((response) => {
        let resData = response.data;

        if (resData.status) {
          const linkElem = document.createElement("a");
          linkElem.href = resData.data.downloadUrl;
          document.body.appendChild(linkElem);
          linkElem.click();
          document.body.removeChild(linkElem);
        }
        else {
          let errors = resData.error;

          for (let key in errors) {
            this.setAlert({
              show: true,
              variant: "danger",
              message: errors[key].message,
              dismissCountDown: 5000,
            });
          }
        }
      })
      .catch(() => {
        this.setAlert({
          show: true,
          variant: "danger",
          message: "Something went wrong",
          dismissCountDown: 5000,
        });
      })
      .finally(() => {
        this.loading = false;
        this.loadingUrlFor = {
          ...this.loadingUrlFor,
          [sessionId]: false
        }
      });
    },
    isCompleted(session) {
      let endTime = this.moment(session.end_time);
      let currentTime = this.moment();
      return endTime.diff(currentTime, "seconds") < 0;
    },
    isInBetween(session) {
      let startTime = this.moment(session.start_time);
      let endTime = this.moment(session.end_time);
      let currentTime = this.moment();
      return currentTime.isBetween(startTime, endTime);
    },
    isUpcoming(session) {
      let startTime = this.moment(session.start_time);
      let endTime = this.moment(session.end_time);
      let currentTime = this.moment();
      return (
        !currentTime.isBetween(startTime, endTime) && !this.isCompleted(session)
      );
    },
    getFileUrl(resource){
      return axios.defaults.baseURL +'file/download?file_id='+ resource
    }
  },
  mounted: function () {
    this.fetchTotebag();
    this.fetchAdertisement()
    this.fetchAgenda();
  },
};
</script>

<style scoped>
.action[aria-disabled='true'] {
  cursor: not-allowed;
}

.action[aria-disabled='false'] {
  cursor: pointer;
}
</style>
