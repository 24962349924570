<template>
  <v-app id="inspire">
    <v-main class="grey lighten-4">
      <v-row
        class="d-flex align-stretch justify-space-between"
        style="height: 100%"
        dense
      >
        <v-col class="pa-0 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <v-card
            height="100%"
            width="100%"
            img="/img/backgroup-image.png"
            class="text-center d-flex align-center"
            flat
            dark
          >
            <v-card-text>
              <v-img :src="logo" max-height="200" class="" contain>
                <template v-slot:placeholder>
                  <div class="d-flex align-center justify-center fill-height">
                    <v-progress-circular
                      color="grey-lighten-4"
                      indeterminate
                    ></v-progress-circular>
                  </div>
                </template>
              </v-img>
              <!-- <div class="mt-5 pt-5">
                 <span class="text-h4">Co-Sponsors</span>
                 <v-row class="row mx-auto mt-3" style="max-width:450px">
                   <v-col cols="6">
                     <v-img src="/img/Milliman logo.jpg"  max-height="80" max-width="250"  class="" contain></v-img>
                   </v-col>
                   <v-col cols="6">
                     <v-img src="/img/Medicus Firm logo.jpg"  max-height="78" max-width="250"  class="" contain></v-img>
                   </v-col>
                 </v-row>
               </div> -->
            </v-card-text>
          </v-card>
        </v-col>
        <v-col class="pa-0 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <v-card
            class="d-flex align-center justify-center flex-column"
            flat
            height="100%"
          >
            <router-view />
            <div class="d-flex justify-center my-5">
              <v-btn
                color="secondary"
                elevation="0"
                :href="agendaLink"
                target="_blank"
                class="mr-5"
                :disabled="loadingAgendaDetails"
              >
                Agenda
              </v-btn>
              <!-- <v-btn  color="secondary" elevation="0"  :href="session_agenda_url" target="_blank" class="ml-5">Session Agenda</v-btn> -->
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-main>
  </v-app>
</template>

<script>
import axios from "@/helper/axios";

export default {
  name: "Login",
  data() {
    return {
      logo: "",
      form_error: {},
      email: null,
      password: null,
      loading: false,
      data: {},
      session_agenda_url: process.env.VUE_APP_ASSETS + "Sessions Agenda.pdf",
      agendaLink: null,
      loadingAgendaDetails: false,
    };
  },
  methods: {
    getObjects() {
      let _self = this;
      axios
        .get("logo/get")
        .then(function(response) {
          _self.logo = response.data.data[0].upload_url;
        })
        .catch(function(e) {
          console.log(e);
        });
      _self.loading = false;
    },
    getAgendaDetails() {
      this.loadingAgendaDetails = true;

      axios
        .get("agenda")
        .then((response) => {
          const data = response.data;

          if (data.status) {
            const agendaDetails = data.data.find((item) => {
              return item.page_position.find((pp) => {
                return pp.value === "AGENDA_FILE";
              });
            });

            if (agendaDetails) {
              this.agendaLink = agendaDetails.upload_url;
            }
          }
        })
        .finally(() => {
          this.loadingAgendaDetails = false;
        });
    },
  },
  created() {
    this.getAgendaDetails();
  },
  mounted: function() {
    this.getObjects();
    // setInterval(() => {
    //   this.outlined = !this.outlined
    //   this.session_outlined = !this.session_outlined
    // },1500)
  },
};
</script>
<style scoped></style>
