const state = {
  show_vendor_directory_modal : false,
  vendor_list : {},
  search_vendor : '',
  category_id : ''
}
const actions = {
  fetchVendor({commit,state}){
    axios.get("/vendor/list?search="+state.search_vendor+'&category_id='+state.category_id)
    .then(function (response) {
      let res_data = {
        key : 'vendor_list',
        data : response.data.data
      }
      commit('setVendorData',res_data)
    })
    .catch(function (e) {
      console.log(e);
    });
  }
}
const getters = {}
const mutations = {
  setVendorData(state,data){
    state[data.key] = data.data
  },
}
import axios from "@/helper/axios";
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}