<template>
  <div style="height: 100%">
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 1920 1440"
      style="enable-background: new 0 0 1920 1440"
      xml:space="preserve"
    >
      <g id="Background_xA0_Image_1_">
        <image
          style="overflow: visible"
          id="Background_xA0_Image"
          :xlink:href="getImagePath"
        ></image>
      </g>
      <g id="Clickable_Vendor_Logo_links_1_" class="st0">
        <g
          id="Vendor_Logo_link_1_xA0_Image_1_"
          :class="object_list.WELCOME_LOGO_LINK ? 'cursor_pointer' : ''"
          @click="$helper.openUrl(object_list.WELCOME_LOGO_LINK)"
        >
          <image
            style="overflow: visible;"
            width="500"
            height="180"
            x="550"
            y="316"
            id="Vendor_Logo_link_1_xA0_Image"
            :xlink:href="object_list.WELCOME_LOGO"
          ></image>
        </g>
      </g>

      <image
        style="overflow: visible;cursor: pointer;"
        width="320"
        height="180"
        x="1050"
        y="316"
        id="Area_reserved_for_Video_xA0_Image"
        ref="Area_reserved_for_Video_xA0_Image"
        :xlink:href="object_list.INTRODUCTION_VIDEO_LOGO"
        @click="ShowVideo(object_list.INTRODUCTION_VIDEO)"
        preserveAspectRatio="none"
      ></image>

      <!---------BOOTH ---------->

      <g
        v-for="key in Object.keys(booths)"
        :key="key"
        @click="
          openBoothDetails(
            booths[key].vendor_details.vendor_id,
            booths[key].booth_id
          )
        "
        class="cursor_pointer"
      >
        <g id="_x35__6_">
          <g>
            <rect
              :x="booths[key].rect[0]"
              :y="booths[key].rect[1]"
              class="st1"
              width="200"
              height="70"
            />
          </g>
        </g>
        <g id="DEMO_LOGO_copy_xA0_Image_15_">
          <image
            style="overflow: visible"
            width="200"
            height="70"
            :x="booths[key].img[0]"
            :y="booths[key].img[1]"
            :xlink:href="booths[key].vendor_details.profile_pic"
          ></image>
        </g>
      </g>

      <!---------BOOTH  END---------->
      <g
        id="Rectangle_2_2_"
        class="st1 cursor_pointer"
        @click="openPage('session')"
      >
        <defs>
          <rect
            id="SVGID_3_"
            x="720"
            y="700"
            class="st1"
            width="500"
            height="150"
          />
        </defs>
        <clipPath id="SVGID_4_">
          <use xlink:href="#SVGID_3_" style="overflow: visible" />
        </clipPath>

        <image
          style="overflow: visible; clip-path: url(#SVGID_4_);"
          width="1920"
          height="1440"
          id="Rectangle_2_xA0_Image"
          :xlink:href="layer_image"
        ></image>
      </g>
      <g
        id="Rectangle_1_2_"
        class="st1 cursor_pointer"
        @click="openPage('/app/info-desk')"
      >
        <defs>
          <rect id="SVGID_5_" y="850" class="st1" width="400" height="100" />
        </defs>
        <clipPath id="SVGID_6_">
          <use xlink:href="#SVGID_5_" style="overflow: visible" />
        </clipPath>

        <image
          style="overflow: visible; clip-path: url(#SVGID_6_)"
          width="1920"
          height="1440"
          id="Rectangle_1_xA0_Image"
          :xlink:href="layer_image"
        ></image>
      </g>
    </svg>
    <v-dialog v-model="show_preview" max-width="50%">
      <v-card>
        <v-card-text class="pt-2 rounded-lg px-0 py-0">
          <video
            :src="preview_data"
            width="100%"
            autoplay
            v-if="preview_data"
            controls
          ></video>
        </v-card-text>
        <v-btn
          style="position:absolute;top:5px;right:5px"
          color="red"
          dark
          fab
          small
          @click="show_preview = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card>
    </v-dialog>
    <v-tour
      name="myTour"
      :steps="steps"
      :callbacks="tourCallbacks"
      :options="{ highlight: true }"
    >
    </v-tour>
    <v-dialog v-model="tour_dialog" max-width="300" persistent>
      <v-card color="primary">
        <v-card-text class="px-5 py-2">
          <span class="white--text body-1 text-justify"
            >Welcome to the tutorial for the Virtual Exhibit Hall Platform! This
            will cover some of the navigation items for the website.
          </span>
        </v-card-text>
        <v-card-actions>
          <v-btn small color="red" dark @click="onFinishTour()">skip</v-btn>
          <v-spacer></v-spacer>
          <v-btn small color="green" dark @click="startTour()">next</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "@/helper/axios";
export default {
  name: "MainImage",
  data() {
    return {
      loading: false,
      preview_data: null,
      show_preview: false,
      object_list: {
        WELCOME_LOGO: "",
        WELCOME_LOGO_LINK: "",
        INTRODUCTION_VIDEO: "",
        INTRODUCTION_VIDEO_LINK: "",
        INTRODUCTION_VIDEO_LOGO: "",
        INTRODUCTION_VIDEO_LOGO_LINK: "",
      },
      booths: {},
      booths_length: 0,
      steps: [
        {
          target: ".v-step-1",
          content: `Clicking the Lobby Icon will take you back to this page where you can see all of the Booths and Auditorium.`,
        },
        {
          target: ".v-step-2",
          content: `Clicking the Session Icon will take you to the Auditorium where you can see all ongoing, upcoming and completed sessions`,
        },
        {
          target: ".v-step-3",
          content: `Clicking the Networking Room Icon will take you to a communal lounge for all of the Attendees and Exhibitors. Here you will be able to meet new friends and catch up with old ones.`,
        },
        {
          target: ".v-step-4",
          content: `Clicking on the Info Desk Icon will take you to our help desk.  Here you can ask the agents any questions or issues you may be having on the platform. `,
        },
        {
          target: ".v-step-5",
          content: `Clicking on the Agenda Icon will bring up the schedule for PCM Virtual Symposium 2023.  Please feel free to check out all the sessions the conference will be offering!`,
        },
        {
          target: ".v-step-6",
          content: `Clicking on the Tote Bag Icon will populate your personal tote bag!  Here you can add any documents you enjoyed viewing while visiting all of the booths.  You will then be able to download the documents and browse at your own pace. `,
        },
        {
          target: ".v-step-7",
          content: `Clicking the Profile Icon will navigate to the profile editing page.  Here you can update your information like name, password, profile picture, etc.`,
        },
        {
          target: ".v-step-8",
          content: `Clicking the Chat icon will show all of your current messages. You can now click on one of the Booths and start visiting our Vendors!  `,
        },
      ],
      tourCallbacks: {
        onFinish: this.onFinishTour,
        onSkip: this.onFinishTour,
      },
      tour_dialog: false,
    };
  },
  methods: {
    getObjects() {
      let _self = this;
      axios
        .get("object/get?page_name=MAIN_LOBBY")
        .then(function(response) {
          // _self.object_list = response.data.data
          for (let key in response.data.data) {
            let data = response.data.data[key];
            // let field_name = data.page_position[0].value;
            // let value_of_field = data.upload_url;
            _self.object_list[data.page_position[0].value] = data.upload_url;
            _self.object_list[data.page_position[0].value + "_LINK"] =
              data.link;
          }
        })
        .catch(function(e) {
          console.log(e);
        });
      _self.loading = false;
    },
    getVendors() {
      this.loading = true;
      axios
        .get("/room/details?room_id=1")
        .then((response) => {
          if (response.data.status) {
            this.booths_length = response?.data?.data?.length || 0;

            const data = response.data.data;

            for (let key in response.data.data) {
              let data = response.data.data[key];
              this.booths[data.booth_id] = {
                ...data,
                rect: [],
                img: [],
              };
            }

            if (this.booths_length === 3) {
              this.booths = {
                A1: {
                  ...this.booths.A1,
                  rect: ["592", "562"],
                  img: ["593", "561"],
                },
                A2: {
                  ...this.booths.A2,
                  rect: ["859", "562"],
                  img: ["858", "561"],
                },
                A3: {
                  ...this.booths.A3,
                  rect: ["1128", "562"],
                  img: ["1128", "561"],
                },
              };
            } else if (this.booths_length === 2) {
              this.booths = {
                [data[0].booth_id]: {
                  ...this.booths[data[0].booth_id],
                  rect: ["702", "560"],
                  img: ["700", "558"],
                },
                [data[1].booth_id]: {
                  ...this.booths[data[1].booth_id],
                  rect: ["1022", "560"],
                  img: ["1022", "558"],
                },
              };
            } else if (this.booths_length === 1) {
              this.booths = {
                [data[0].booth_id]: {
                  ...this.booths[data[0].booth_id],
                  rect: ["860", "562"],
                  img: ["860", "561"],
                },
              };
            }
          }
        })
        .catch(function(e) {
          console.log(e);
        })
        .then(() => {
          this.loading = false;
        });
    },
    openBoothDetails(vendor_id, booth_id) {
      if (vendor_id) {
        this.$router.push("/app/vendor/" + vendor_id + "/" + booth_id);
      }
    },
    ShowVideo(video_src) {
      this.show_preview = true;
      this.preview_data = video_src;
    },
    openPage(route) {
      this.$router.push(route);
    },
    startTour() {
      this.$tours["myTour"].start();
      this.tour_dialog = false;
    },
    onFinishTour() {
      this.ShowVideo(this.object_list.INTRODUCTION_VIDEO);
      this.tour_dialog = false;
      localStorage.setItem("tour_completed", true);
    },
  },
  watch: {
    show_preview: function(value) {
      if (!value) {
        this.preview_data = null;
      }
    },
  },
  computed: {
    getImagePath() {
      return (
        process.env.VUE_APP_ASSETS +
        `lobby/PCM_main_lobby_${this.booths_length}_booths_4x3.png`
      );
    },
    intro_video_thumb() {
      return process.env.VUE_APP_ASSETS + "intro_video_thumb.png";
    },
    layer_image() {
      return process.env.VUE_APP_ASSETS + "layer_img.png";
    },
  },
  created() {
    this.loading = true;
    this.getObjects();
    this.getVendors();
  },
  mounted() {
    let tour_completed = localStorage.getItem("tour_completed");
    if (!tour_completed) {
      this.tour_dialog = true;
    }
  },
};
</script>
<style type="text/css">
.st0 {
  opacity: 1 !important;
}
.st1 {
  enable-background: new;
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #ffff;
}
.v-tour__target--highlighted {
  pointer-events: none !important;
}
.v-step__content {
  text-align: justify;
}
.v-step {
  background: #80bc40 !important;
}
.v-step[x-placement^="top"] .v-step__arrow {
  border-color: #80bc40 !important;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
}
.v-step[x-placement^="bottom"] .v-step__arrow {
  border-color: #80bc40 !important;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-top-color: transparent !important;
}
</style>
