var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"notification_root_div"},[_c('v-expansion-panels',{staticClass:"rounded-tr-xl",attrs:{"accordion":"","flat":"","tile":"","value":_vm.is_msg_expand},model:{value:(_vm.is_expanded),callback:function ($$v) {_vm.is_expanded=$$v},expression:"is_expanded"}},[_c('v-expansion-panel',{staticClass:"pa-0"},[(_vm.is_expanded)?_c('v-expansion-panel-header',{staticClass:"pa-0 px-2 v-step-8",attrs:{"hide-actions":"","color":"orange"}},[_c('v-badge',{attrs:{"color":"secondary","content":_vm.unreadTotalCount,"top":"","overlap":"","value":_vm.unreadTotalCount}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-message-text-outline")]),_c('span',{staticClass:"white--text mx-2"},[_vm._v("Message Center")])],1)],1):_vm._e(),_c('v-expansion-panel-content',{staticClass:"notification_list"},[_c('v-card',{attrs:{"tile":""}},[_c('v-card-title',{staticClass:"px-1 py-1 secondary  white--text d-flex justify-space-between"},[_c('span',[_vm._v("Chat")]),_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-width":150},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","color":"primary","dark":"","depressed":""}},'v-btn',attrs,false),on),[_c('span',[_vm._v("Start new chat")]),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-plus")])],1)]}}]),model:{value:(_vm.search_box),callback:function ($$v) {_vm.search_box=$$v},expression:"search_box"}},[_c('SearchAttendee',{on:{"closeMenu":function($event){_vm.search_box = false}}})],1),_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":function($event){_vm.is_expanded = !_vm.is_expanded}}},[_c('v-icon',[_vm._v("mdi-arrow-down-drop-circle-outline")])],1)],1),_c('v-card-text',{staticClass:"pa-0"},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"chat-area-list d-flex align-start flex-column",attrs:{"cols":"3"}},[(_vm.onlyActiveMembers.length)?_c('v-list',{staticClass:"pa-0",attrs:{"dense":"","width":"100%"}},[_vm._l((_vm.onlyActiveMembers),function(attendee){return [_c('v-list-item',{key:attendee.msg_id,staticClass:"px-1 pa-0 custom-list",class:{
                        primary:
                          _vm.selected_private_user &&
                          _vm.selected_private_user.attendee_id ==
                            attendee.attendee_id,
                      },on:{"click":function($event){return _vm.openPrivateChat(attendee)}}},[_c('v-list-item-content',{class:{
                          'white--text':
                            _vm.selected_private_user &&
                            _vm.selected_private_user.attendee_id ==
                              attendee.attendee_id,
                        }},[_c('v-list-item-title',{staticClass:"d-flex"},[_c('span',{staticClass:"small-font text-truncate pa-0 name",class:{
                              'col-10': attendee.total_unread_msg,
                              'col-12': !attendee.total_unread_msg,
                            }},[_vm._v(" "+_vm._s(attendee.first_name)+" "+_vm._s(attendee.last_name)+" ")]),(attendee.total_unread_msg)?_c('span',{staticClass:"col-2 pa-0"},[_vm._v("("+_vm._s(attendee.total_unread_msg)+")")]):_vm._e(),_c('v-spacer')],1)],1)],1)]})],2):_vm._e()],1),_c('v-col',{staticClass:"min-h-400",attrs:{"cols":"9"}},[(_vm.is_private_chat)?_c('ChatBox'):_c('v-card',{staticClass:"ma-0 d-flex align-center grey",attrs:{"flat":"","height":"100%","tile":""}},[_c('v-card-text',{staticClass:"text-center"},[_c('span',{staticClass:"text-h5"},[_vm._v("Messages will appear here")])])],1)],1)],1)],1)],1)],1)],1)],1),_c('div',{staticClass:"quick_notifications"},_vm._l((_vm.quick_notifications),function(notification,index){return _c('v-alert',{key:index,attrs:{"dismissible":"","color":notification.color ? notification.color : 'primary',"border":"left","elevation":"2","colored-border":"","icon":"mdi-message","max-width":notification.max_width}},[(notification.data.type == 'private_message')?_c('div',{staticClass:"name"},[_vm._v(" You have new message from "+_vm._s(notification.data.attendee_data.first_name)+" "+_vm._s(notification.data.attendee_data.last_name)+" "),_c('v-btn',{attrs:{"small":"","text":"","color":"success","dark":""},on:{"click":function($event){return _vm.openPrivateChat(notification.data.attendee_data, true)}}},[_vm._v("Reply "),_c('v-icon',[_vm._v("mdi-reply")])],1)],1):(notification.data.type == 'infodesk')?_c('div',[_vm._v(" You have new message from "+_vm._s(notification.data.attendee_data.first_name)+" "+_vm._s(notification.data.attendee_data.last_name)+" in Infodesk ")]):(notification.data.type == 'booth_visit')?_c('div',{staticClass:"name"},[_vm._v(" "+_vm._s(notification.data.first_name)+" "+_vm._s(notification.data.last_name)+" Visted your booth ")]):_c('div',[_c('span',{staticClass:"text-justify"},[_vm._v(_vm._s(notification.data.message))])])])}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }