const state = {
  session_info : null
}
const actions = {
  setSession({commit},data){
    commit('setSession',data)
  },
  removeSession({commit}){
    commit('removeSession')
  }
}
const getters = {}
const mutations = { 
  setSession(state,data) {
    state.session_info = data
  },
  removeSession(state) {
    state.session_info = null
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}