import Vue from "vue";
import Vuetify from "vuetify/lib";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        secondary: "#792d8d",
        primary: "#114c8f",
        error: "#EF5350",
      },
      dark: {
        secondary: "#1a4476",
        primary: "#80bc40",
        error: "#EF5350",
      },
    },
    dark: false,
  },
});
