import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify';
import Ws from '@adonisjs/websocket-client'
import WsPlugin from 'adonis-vue-websocket'
import store from './store'
import moment from 'moment'
import vueDebounce from 'vue-debounce'
import helper from './helper/helper';
import VueTour from 'vue-tour'
import i18n from './helper/language'
require('vue-tour/dist/vue-tour.css')
Vue.prototype.FRONT_ASSETS = process.env.VUE_APP_ASSETS
Vue.prototype.moment = moment
Vue.prototype.$helper = helper
Vue.config.productionTip = false
Vue.use(WsPlugin, { adonisWS: Ws })
Vue.use(vueDebounce)
Vue.use(VueTour)
new Vue({
  i18n,
  router,
  vuetify,
  store,
  render: h => h(App)
}).$mount('#app')
