import Vue from 'vue'
import Vuex from 'vuex'
import utils from './utils'
import active_session from './active_session'
import notifications from './notifications'
import attendees from './attendees'
import totebag from './totebag'
import agenda from './agenda'
import vendor_directory from './vendor_directory'
import resource from './resource'
import public_chat from './public_chat'
import vendor from './vendor'
import vendor_booth from './vendor_booth'
Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    utils,
    active_session,
    notifications,
    attendees,
    totebag,
    agenda,
    vendor_directory,
    resource,
    public_chat,
    vendor,
    vendor_booth
  }
})

export default store