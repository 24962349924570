const state = {
  online_status : false,
  alert: {
    show: false,
    position: 'bottom',
    variant: 'success',
    message: '',
    dismissCountDown: 0,
  },
  maxWidth : process.env.VUE_APP_MAX_WIDTH,
  maxPopupWidth : process.env.VUE_APP_POPUP_MAX_WIDTH,
  pageBackgroundImage:''
}
const actions = {}
const getters = {}
const mutations = {
  setAlert(state, data) {
    state.alert.show = data.show
    state.alert.position = data.position
    state.alert.variant = data.variant
    state.alert.message = data.message
    state.alert.dismissCountDown = data.dismissCountDown
  },
  setBackground(state, data){
    state.pageBackgroundImage = data.image;
  },
  setOnlineStatus(state,status) {
    state.online_status = status
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}