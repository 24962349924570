<template>
  <div class="notification_root_div">
    <v-expansion-panels
      accordion
      flat
      tile
      class="rounded-tr-xl"
      v-model="is_expanded"
      :value="is_msg_expand"
    >
      <v-expansion-panel class="pa-0">
        <v-expansion-panel-header
          hide-actions
          class="pa-0 px-2 v-step-8"
          v-if="is_expanded"
          color="orange"
        >
          <v-badge
            color="secondary"
            :content="unreadTotalCount"
            top
            overlap
            :value="unreadTotalCount"
          >
            <v-icon dark>mdi-message-text-outline</v-icon>
            <span class="white--text mx-2">Message Center</span>
          </v-badge>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="notification_list">
          <v-card tile>
            <v-card-title
              class="px-1 py-1 secondary  white--text d-flex justify-space-between"
            >
              <span>Chat</span>
              <v-menu
                v-model="search_box"
                :close-on-content-click="false"
                :nudge-width="150"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    small
                    color="primary"
                    dark
                    depressed
                    v-bind="attrs"
                    v-on="on"
                  >
                    <span>Start new chat</span>
                    <v-icon right>mdi-plus</v-icon>
                  </v-btn>
                </template>
                <SearchAttendee @closeMenu="search_box = false" />
              </v-menu>
              <v-btn @click="is_expanded = !is_expanded" icon dark>
                <v-icon>mdi-arrow-down-drop-circle-outline</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text class="pa-0">
              <v-row no-gutters>
                <v-col
                  cols="3"
                  class="chat-area-list d-flex align-start flex-column"
                >
                  <v-list
                    dense
                    class="pa-0"
                    v-if="onlyActiveMembers.length"
                    width="100%"
                  >
                    <template v-for="attendee in onlyActiveMembers">
                      <v-list-item
                        :key="attendee.msg_id"
                        @click="openPrivateChat(attendee)"
                        class="px-1 pa-0 custom-list"
                        :class="{
                          primary:
                            selected_private_user &&
                            selected_private_user.attendee_id ==
                              attendee.attendee_id,
                        }"
                      >
                        <v-list-item-content
                          :class="{
                            'white--text':
                              selected_private_user &&
                              selected_private_user.attendee_id ==
                                attendee.attendee_id,
                          }"
                        >
                          <v-list-item-title class="d-flex">
                            <span
                              class="small-font text-truncate pa-0 name"
                              :class="{
                                'col-10': attendee.total_unread_msg,
                                'col-12': !attendee.total_unread_msg,
                              }"
                            >
                              {{ attendee.first_name }}
                              {{ attendee.last_name }}
                            </span>
                            <span
                              v-if="attendee.total_unread_msg"
                              class="col-2 pa-0"
                              >({{ attendee.total_unread_msg }})</span
                            >
                            <v-spacer></v-spacer>
                            <!-- <v-icon @click="removeTemp(attendee)">mdi-delete</v-icon> -->
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-list>
                </v-col>
                <v-col cols="9" class="min-h-400">
                  <ChatBox v-if="is_private_chat" />
                  <v-card
                    flat
                    v-else
                    class="ma-0 d-flex align-center grey"
                    height="100%"
                    tile
                  >
                    <v-card-text class="text-center">
                      <span class="text-h5">Messages will appear here</span>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <div class="quick_notifications">
      <v-alert
        dismissible
        :color="notification.color ? notification.color : 'primary'"
        border="left"
        elevation="2"
        colored-border
        icon="mdi-message"
        v-for="(notification, index) in quick_notifications"
        :key="index"
        :max-width="notification.max_width"
      >
        <div v-if="notification.data.type == 'private_message'" class="name">
          You have new message from
          {{ notification.data.attendee_data.first_name }}
          {{ notification.data.attendee_data.last_name }}
          <v-btn
            small
            text
            color="success"
            dark
            @click="openPrivateChat(notification.data.attendee_data, true)"
            >Reply <v-icon>mdi-reply</v-icon></v-btn
          >
        </div>
        <div v-else-if="notification.data.type == 'infodesk'">
          You have new message from
          {{ notification.data.attendee_data.first_name }}
          {{ notification.data.attendee_data.last_name }} in Infodesk
        </div>
        <div v-else-if="notification.data.type == 'booth_visit'" class="name">
          {{ notification.data.first_name }}
          {{ notification.data.last_name }} Visted your booth
        </div>
        <div v-else>
          <span class="text-justify">{{ notification.data.message }}</span>
        </div>
      </v-alert>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations, mapGetters } from "vuex";
import ChatBox from "./ChatBox";
import SearchAttendee from "./SearchAttendee";
import { getCallStatusCode } from "../../helper/attendee";

export default {
  name: "Notifications",
  components: {
    ChatBox,
    SearchAttendee,
  },
  data() {
    return {
      selected_chat: null,
      is_reply: false,
      search_box: false,
    };
  },
  watch: {
    is_msg_expand: function(val) {
      if (val == true) {
        this.setChatPrivate(false);
        this.setPrivateChatUser({});
      }
    },
  },
  computed: {
    ...mapState("notifications", [
      "is_msg_expand",
      "unread_counter",
      "infodesk_messages",
      "is_private_chat",
      "private_messages",
      "quick_notifications",
      "selected_private_user",
    ]),
    ...mapGetters("notifications", ["unreadTotalCount"]),
    is_expanded: {
      get: function() {
        return this.is_msg_expand;
      },
      set: function(newValue) {
        this.setExpandBox(newValue);
      },
    },
    reversedNotifications() {
      let reverseArray = [];
      if (this.infodesk_messages.length) {
        for (
          var i = 0, j = this.infodesk_messages.length - 1;
          i < this.infodesk_messages.length;
          i++, j--
        ) {
          reverseArray.push(this.infodesk_messages[j]);
        }
        return reverseArray;
      }
      return reverseArray;
    },
    myself() {
      return JSON.parse(localStorage.getItem("pcm_user"));
    },
    onlyActiveMembers() {
      return this.private_messages.filter((attendee) => {
        return !attendee.temp_removed || attendee.temp_removed == false;
      });
    },
  },
  methods: {
    ...mapActions("notifications", ["getPrivateMessages"]),
    ...mapMutations("notifications", [
      "setChatUser",
      "setChatPrivate",
      "setPrivateChatUser",
      "setExpandBox",
      "removeTemp",
      "setPrivateMessages",
    ]),
    openPrivateChat(attendee, openSidebar = false) {
      this.setChatPrivate(true);
      this.setPrivateChatUser(attendee);
      if (openSidebar) {
        this.setExpandBox(0);
      }
    },
    closeMenu() {
      this.search_box = false;
    },
    handleAttendeeStatusEvents(data) {
      if (data.attendee_id && Array.isArray(this.private_messages)) {
        const attendeeIndex = this.private_messages.findIndex((attendee) => {
          return attendee.attendee_id == Number(data.attendee_id);
        });

        if (attendeeIndex != -1) {
          const tempAttendees = [...this.private_messages];
          const tempAttendeeData = { ...tempAttendees[attendeeIndex] };

          tempAttendeeData.call_status = getCallStatusCode(data.call_status);
          tempAttendeeData.online_status = Number(data.online_status);

          tempAttendees[attendeeIndex] = tempAttendeeData;

          this.setPrivateMessages(tempAttendees);

          if (this.selected_private_user) {
            const tempPrivateUserData = { ...this.selected_private_user };
            tempPrivateUserData.call_status = getCallStatusCode(
              data.call_status
            );
            tempPrivateUserData.online_status = Number(data.online_status);
            this.setPrivateChatUser(tempPrivateUserData);
          }
        }
      }
    },
    subscribeToChannels() {
      let subscription = this.$ws.socket.getSubscription("attendee_status");

      if (!subscription) {
        subscription = this.$ws.subscribe("attendee_status");

        this.$ws.$on("attendee_status", this.handleAttendeeStatusEvents);
      } else {
        this.$ws.$on("attendee_status", this.handleAttendeeStatusEvents);
      }
    },
    unsubscribeFromChannels() {
      this.$ws.$off("attendee_status", this.handleAttendeeStatusEvents);

      let subscription = this.$ws.socket.getSubscription("attendee_status");

      if (subscription) {
        subscription.close();

        this.$ws.socket.removeSubscription("attendee_status");
      }
    },
  },
  mounted: function() {
    this.getPrivateMessages(true);
    this.subscribeToChannels();
  },
  beforeDestroy() {
    this.unsubscribeFromChannels();
  },
};
</script>
<style>
.notification_root_div {
  position: fixed;
  bottom: 0;
  max-width: 650px;
  z-index: 5;
  left: 0px;
}
.notification_list {
  min-width: 650px;
}
.min-h-400 {
  min-height: 400px;
}
.v-expansion-panel-content__wrap {
  padding: 0px !important;
}
.quick_notifications {
  position: fixed;
  top: 10px;
  right: 10px;
}
.chat-area-list {
  overflow-y: auto;
  min-height: 450px;
  max-height: 450px;
}
.top-right {
  position: fixed !important;
  top: 10px;
  right: 10px;
}
.small-font {
  font-size: 12px;
}
.custom-list {
  min-height: 36px !important;
}
</style>
