<template>
  <v-dialog
    v-model="show_agenda_modal"
    :max-width="maxPopupWidth"
    @click:outside="closePopup"
    persistent
  >
    <v-card>
      <div v-if="!agendaLink" class="loading_iframe_container">
        <div>
          <v-progress-circular
            :width="3"
            color="green"
            indeterminate
          ></v-progress-circular>
          <span class="ml-3">Loading Agenda, Please Wait...</span>
        </div>
      </div>
      <iframe
        v-else
        title="Agenda"
        name="Agenda"
        :src="`${agendaLink}#view=fitH`"
        frameborder="0"
        style="width:100%;height:85vh"
      >
      </iframe>
      <v-btn style="position:absolute;bottom:20px;left:40px" color="red" dark fab small @click="closePopup">
        <v-icon>mdi-close</v-icon>
      </v-btn>      
    </v-card>
  </v-dialog>
</template>
<script>
import axios from "@/helper/axios";
import { mapState, mapActions, mapMutations } from "vuex";
export default {
  name: "AgendaPopup",
  data() {
    return {
      selected : [],
      loading: false,
      english : true,
      agendaLink: null
    };
  },
  watch: {
    show_aganda_modal(value) {
      if (value) {
        this.fetchAgenda();
      }
    },
  },
  computed: {
    ...mapState("agenda", ["show_agenda_modal", "agenda_list"]),
    ...mapState("utils", ["maxPopupWidth"]),
  },
  methods: {
    ...mapMutations("utils", ["setAlert"]),
    ...mapActions("agenda", ["fetchAgenda"]),
    ...mapMutations("agenda", ["setAgendaData"]),
    closePopup() {
      this.setAgendaData({
        key: "show_agenda_modal",
        data: false,
      });
    },
    getAgendaDetails() {
      this.loading = true;

      axios
        .get("object/get?page_name=AGENDA")
        .then((response) => {
          const data = response.data;

          if (data.status) {
            const agendaDetails = data.data.find(item => {
              return item.page_position.find(pp => {
                return pp.value === 'AGENDA_FILE'
              })
            });

            if (agendaDetails) {
              this.agendaLink = agendaDetails.upload_url;
            }
          }
        })
        .finally(() => {
          this.loading = false;
        })
    },
    getTimeSlot(session) {
      return (
        this.moment(session.start_time).format("ddd, MMM Do h:mm a") +
        " - " +
        this.moment(session.end_time).format("h:mm a")
      );
    },
    addEvent(session_id) {
      let _self = this;
      this.loading = "secondary";
      axios.post(`session/add_event`, {session_id:session_id})
        .then((response) => {
          let response_data = response.data;
          if (response_data.status) {
            _self.setAlert({
              show: true,
              variant: "success",
              message: response_data.message,
              dismissCountDown: 5000,
            });
          }
          else {
            let errors = response_data.error;
            for (let key in errors) {
              _self.setAlert({
                show: true,
                variant: "danger",
                message: errors[key].message,
                dismissCountDown: 5000,
              });
            }
          }
          _self.loading = false;
        })
        .catch((e) => {
          console.log(e);
          _self.loading = false;
        });
    }
  },
  created: function () {
    this.getAgendaDetails();
  },
};
</script>

<style scoped>
.loading_iframe_container {
  width:100%;
  height:85vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>